import React from 'react';
import CustomChatBot from './component/CustomChatBot';
import './assets/css/scss/webapp.scss';
import { bbc_bg ,msgImg,savvyicon} from './config/images';

function App() {
  const [showModal, setShowModal] = React.useState(false);
  const handleFooterClick = () => {
    console.log('Footer clicked!');
    // Add your logic here
    setShowModal(false);
  };
  return (
    <div 
    // add a image background
   style={{
    backgroundImage: `url(${bbc_bg})`,
   }}
    
    className="App">
     {/* <p style={{fontSize: '6rem', color: 'black', fontWeight: 600 }}>Welcome to Xantura Chatbot</p> */}
      
    
      <div style={{
        display:showModal ? 'flex' : 'none',
      }}>

      <CustomChatBot onFooterClick={handleFooterClick} />

      </div>
       <div className='floatButton' style={{
        
        display:showModal ? 'none' : 'flex',
       }} onClick={()=>setShowModal(true)}>
        <img src={savvyicon} alt="Bot" className='msgImg' />
        <p className='_text'>Ask Savvy</p>
      </div>
    {/* <CustomChatBot /> */}
  </div>
  )
}

export default App;
