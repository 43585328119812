// src/services/botService.ts
import axios from 'axios';


interface TokenResponse {
  token: string;
  conversationId: string;
  expires_in: number;
  streamUrl: string;
}

interface RefreshTokenResponse {
  token: string;
  expires_in: number;
  streamUrl: string;
  conversationId: string;
}

class BotService {
  private directLineSecret: string;
  private token: string | null;
  private conversationId: string | null;
  private streamUrl: string | null;
  private tokenExpiryTime: number | null;

  constructor(directLineSecret: string) {
    this.directLineSecret = directLineSecret;
    this.token = null;
    this.conversationId = null;
    this.streamUrl = null;
    this.tokenExpiryTime = null;
  }

  async startConversation(): Promise<void> {
    try {
      
      //savvy
      var theURL = "https://default4f45223a8b224a46a75b845310de5d.25.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cr599_savvyTest/directline/token?api-version=2022-03-01-preview";

      //personal
      // var theURL = "https://default7e0fe792883443ec8c64a8064ccff5.c6.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cr6ec_copilotCv/directline/token?api-version=2022-03-01-preview";

      var environmentEndPoint = theURL.slice(0,theURL.indexOf('/powervirtualagents'));
      var apiVersion = theURL.slice(theURL.indexOf('api-version')).split('=')[1];
      var regionalChannelSettingsURL = `${environmentEndPoint}/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`; 
      var directline;

      
      
      const responseD = await axios.get<TokenResponse>(
        regionalChannelSettingsURL,
      );
      // directline = responseD?.data?.channelUrlsById?.directline;
      
      const response = await axios.get<TokenResponse>(
      theURL,
      );
      

      const responseConversation = await axios.post<TokenResponse>(
        'https://europe.directline.botframework.com/v3/directline/conversations',
        {},
        {
          headers: {
            
            Authorization: `Bearer ${response.data.token}`,
          },
        }
      
      )
      

      this.token = responseConversation.data.token;
      this.conversationId = responseConversation.data.conversationId;
      this.streamUrl = responseConversation.data.streamUrl;
      this.tokenExpiryTime = Date.now() + responseConversation.data.expires_in * 1000;

     
 

    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  }

  async refreshToken(): Promise<void> {
    if (!this.token) {
      throw new Error('No token available to refresh');
    }

    console.log('refreshing token......', this.token);
    
    try {
      const response = await axios.post<RefreshTokenResponse>(
        `https://europe.directline.botframework.com/v3/directline/tokens/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      console.log('response from refresh token', response);
      
      this.token = response.data.token;
      this.conversationId = response.data.conversationId;
      // this.streamUrl = response.data.streamUrl;
      this.tokenExpiryTime = Date.now() + response.data.expires_in * 1000;
    } catch (error) {
      this.startConversation();
      console.error('Error refreshing token:', error);

    }
  }

  //a function that returns token and stream url
  async getStreamUrl(): Promise<any> {
    try {
    
      return this
    } catch (error) {
      console.error('Error getting stream url:', error);
    }
  }

  async ensureTokenIsValid(): Promise<void> {
    if (!this.token || !this.tokenExpiryTime || Date.now() > this.tokenExpiryTime - 60000) {
      await this.refreshToken();
    }
  }

  async sendMessage(message: string): Promise<any> {
    
    
    await this.ensureTokenIsValid();
    const response = await axios.post(
      `https://europe.directline.botframework.com/v3/directline/conversations/${this.conversationId}/activities`,
      {
        attachments: [],
        // channelData: { clientActivityID: 'z4g0zidemso' , attachmentSizes: [] },
        channelId: 'directline',
        type: 'message',
        from: { role: 'user',name: '', id: 'user' },
        text: message,
        textFormat: 'plain',
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );
    return response.data;
  }

  async sendFormData(data: any): Promise<any> {
    
    
    await this.ensureTokenIsValid();
    const response = await axios.post(
      `https://europe.directline.botframework.com/v3/directline/conversations/${this.conversationId}/activities`,
      {
        attachments: [],
        // channelData: { clientActivityID: 'z4g0zidemso' , attachmentSizes: [] },
        channelId: 'directline',
        type: 'message',
        from: { role: 'user',name: '', id: 'user' },
        value: data,
        textFormat: 'plain',
        text: 'form submitted',

      }
      ,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );
    return response.data;
  }

  async getMessages(): Promise<any> {
    console.log('getting new messages......');
    
    await this.ensureTokenIsValid();

    
    const response = await axios.get(
      `https://europe.directline.botframework.com/v3/directline/conversations/${this.conversationId}/activities`,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );
    console.log('response from get messages function', response);
    return response.data.activities;
  }

  async startEndConversationWithType(type: string): Promise<any> {
    
    
    await this.ensureTokenIsValid();
    // endOfConversation
    const response = await axios.post(
      `https://europe.directline.botframework.com/v3/directline/conversations/${this.conversationId}/activities`,
      {
        attachments: [],
        // channelData: { clientActivityID: 'z4g0zidemso' , attachmentSizes: [] },
        channelId: 'directline',
        type: type === 'endOfConversation' ? 'endOfConversation' : 'event',
        from: { role: 'user',name: '', id: 'user' },
        serviceUrl: "https://europe.directline.botframework.com/",
        name:'startConversation',
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );
    return response.data;
  }

  // async endConversationWithType(type: string): Promise<any> {
    
    
  //   await this.ensureTokenIsValid();
  //   // endOfConversation
  //   const response = await axios.post(
  //     `https://europe.directline.botframework.com/v3/directline/conversations/${this.conversationId}/activities`,
  //     {
  //       attachments: [],
  //       // channelData: { clientActivityID: 'z4g0zidemso' , attachmentSizes: [] },
  //       channelId: 'directline',
  //       type: 'endOfConversation',
  //       from: { role: 'user',name: '', id: 'user' },
  //       serviceUrl: "https://europe.directline.botframework.com/",
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${this.token}`,
  //       },
  //     }
  //   );
  //   return response.data;
  // }
}






export default BotService;
