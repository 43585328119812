
import { initReactI18next } from 'react-i18next';
import en from '../lang/en';
import i18next from 'i18next';

i18next.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  resources: { en }
})
export default i18next;