import React, { useEffect, useState, useRef } from 'react';
import BotService from '../services/botService';
import { downArrow, msgImg, sendImg,savvyicon } from '../config/images';
import Markdown from 'react-markdown'

import I18n from '../config/I18n';
import { log } from 'console';
import Selector from './Selector';
interface Message {
  type: string;
  id: string;
  text: string;
  from: {
    role: string; id: string 
};
  attachments?: Attachment[];
  textFormat?: string;
  suggestedActions?: {
    actions: any[];

  }
}

interface Attachment {
  contentType: string;
  content: any;
}
interface Inputs {
  [key: string]: string;
}

interface CustomChatBotProps {
  onFooterClick: () => void;
}

const CustomChatBot: React.FC<CustomChatBotProps> = ({onFooterClick}) => {
const [inputs, setInputs] = useState<Inputs>({});

const [showInput, setShowInput] = useState<boolean>(true);
  const [initializedChat, setInitializedChat] = useState<boolean>(false);
  const initializeChatRef = useRef<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [buttons, setButtons] = useState<any[]>([]);
  const [rate, setRate] = useState<number>(0);
  const botServiceRef = useRef<BotService | null>(null);
  let _button: any[] = [];

  const chat = useRef<HTMLDivElement>(null);
  
  const socketRef = useRef<WebSocket | null>(null);
  function openWebSocket(streamUrl:any) {
    const socket = new WebSocket(streamUrl);
    socketRef.current = socket;
    // Listen for messages
    socket.onopen = () => {
      console.log('WebSocket connection opened');
      
    }
    socket.onmessage = (event) => {
      
      if (!event.data) return;
      
      const data = JSON.parse(event.data);
      console.log('Received message:', data.activities[0]);

      const message = data.activities[0];
      if (message.type === 'message') {
        if(message.text === 'form submitted') return;
        setMessages((prevMessages) => [...prevMessages, message]);
      }

      // if message has any suggested actions or attachments then hide the input field and only when its from.role is from the bot
      if(message.from.role === 'bot'){
        if (
          message.attachments && message.attachments.length > 0
          ||
          message.suggestedActions && message.suggestedActions.actions.length > 0
        ){
        setShowInput(false);
      } else {
        setShowInput(true);
      }
      }
     
      //scroll to the bottom of the chat
      
      console.log('scrolling to the bottom');
      setTimeout(() => {
        chat.current?.scrollTo({behavior: "smooth", top: chat.current.scrollHeight});
      }, 0);
      
    };
  
    // Handle errors
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    // Handle close event
    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }
  
  const initializeChat = async () => {
    
    if(initializedChat) return;
    setInitializedChat(true);

    const botService = new BotService('lbgEwl8jA54.umt09e0eLpHuxLQ5OFedOD8s5R5vvZV31zAjMxGk4wA');
    botServiceRef.current = botService;
    
    setLoading(true);
    await botService.startConversation();
    
    console.log('service started');
    
    // await botService.sendMessage(''); // Send initial message
      
      const rss = await botService.getStreamUrl();
          openWebSocket( rss.streamUrl);


          await botService.startEndConversationWithType('startConversation');
    console.log('message sent');
    

  //call the refresh token function every 30 and skip the first call
    setInterval(async () => {
      
          console.log('refreshing token being fetched every 15 minutes');
          
       await botService.refreshToken();



    }, 
    120000);
  
    
    // const initialMessages = await botService.getMessages();
    // console.log('initialMessages', initialMessages);
    // setMessages(initialMessages);
    setLoading(false);
  };

  useEffect(() => {
    
    initializeChat();
  }, []);

  useEffect(() => {

  }, [selectedOption,_button]);

  const handleSendMessage = async () => {
    console.log(messages,'fsdsfdrtefrds');

    if (inputValue.trim()) {
      const botService = botServiceRef.current;
      if (botService) {
        setLoading(true);
       await botService.sendMessage(inputValue);
       
        console.log('sending new message');
        
          // const newMessages = await botService.getMessages();
          // console.log('response from chatbot == >>>>', newMessages);
          // setMessages(newMessages);
          setLoading(false);
    
      }
      setInputValue('');
      setSelectedOption(null);
      
    }
  };


  const handleOptionClick = async (button: any) => {
    setShowInput(false);

    setSelectedOption(button.title);
    _button = [];
    if(button.type == "openUrl")
    {
      window.open(button.value,'_blank');
      return 
    }
    setLoading(true);

    const botService = botServiceRef.current;
    if (botService) {
      //update the last message to remove the suggested actions
       
    
      // setMessages((prevMessages) => {
      //   const lastMessage = prevMessages[prevMessages.length - 1];
      //   if (lastMessage.suggestedActions) {
      //     lastMessage.suggestedActions = undefined;
      //   }

      //   return [...prevMessages];
      // });


      await botService.sendMessage(button.title);
      // const newMessages = await botService.getMessages();
      // setMessages(newMessages);
      setLoading(false);
    }
    setSelectedOption(null);
  };

  const renderMessage = (message: Message) => {
    if (message.attachments && message.attachments.length > 0) {
      
      return (
        <div 
        // className={`message ${message.from?.role === 'bot' ? 'bot' : 'user'}`}
         key={message.id}>
          {message.attachments.map((attachment, index) => renderAttachment(attachment, index))}
        </div>
      );
    }else if(message.textFormat === 'markdown'){
      
      return (
        <>
 <div className={`message ${message.from?.role === 'bot' ? 'bot' : 'user'}`} key={message.id}>
          <Markdown 
        
          

          className='_text'>{message.text}</Markdown>
        </div>
        {
          message.suggestedActions && message.suggestedActions.actions.length > 0 && (
            <Selector options={message.suggestedActions.actions}  onChange={handleOptionClick} />
       
          )
        }
        </>
       
      )
    }
    
    return (
      <div className={`message ${message.from?.role === 'bot' ? 'bot' : 'user'}`} key={message.id}>
        <p className='_text'>{message.text}</p>
      </div>
    );
  };

  const renderAttachment = (attachment: Attachment, index: number) => {
     
    switch (attachment.contentType) {
      case 'application/vnd.microsoft.card.hero':
        return renderHeroCard(attachment.content, index);
      case 'application/vnd.microsoft.card.adaptive':
        return RenderAdaptiveCard(attachment.content, index);
      default:
        return null;
    }
  };
  const renderHeroCard = (content: any, index: number) => {
    const ratingButtons = content.buttons.filter((button: any) => button.type === 'rating');
    
    return (
      <div className="hero-card" key={index}>
        {content.images && content.images.length > 0 && (
          <img src={content.images[0].url} alt={content.title} className="hero-card-image" />
        )}
        <h3 className='_textTitle'>{content.title}</h3>
        <p className='_text'>{content.text}</p>
        {ratingButtons.length > 0 && (
          <div className="hero-card-buttons ">
            {ratingButtons.map((button: any, idx: number) => (
              <button
                key={idx}
                className={button.title === inputValue ? 'hero-card-rating selected' : 'hero-card-rating'}
                onClick={() => handleOptionClick(button)}
              >
                {button.title}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const RenderAdaptiveCard = (content: any, index: number) => {
    /* this will have form and object will have this structure

errorMessage: 
"Please provide your lastname"
id: "txt_lastname"
isRequired: true
label: "Last name"
placeholder: "Enter your last name"
type: "Input.Text"

and based on type we can render the form item and at the end there will be a submit button with below structure 
actions: 
[{
data: {
    actionSubmitId: 'Submit'
},
id:'bt_submit',
type: 'Action.Submit',
title: 'Submit'
}]
}}]
horizontalAlignment: "Center"
id: "bu_submit"
spacing: "medium"
type: "ActionSet"

another form item can be a text block with below structure

color: "dark"
horizontalAlignment: "center"
id: "lb_PII_details"
style: "heading"
text: "Please fill in your details  below"
type: "TextBlock"
wrap: true

another form item can be a date picker with below structure


errorMessage: "Please provide your date of birth"
id: "txt_dob"
isRequired: true
label: "Date of birth"
type: "Input.Date"

another form item can be "ColumnSet" rate picker with below structure
columns: [{
    items: [{
    altText: "1",
    horizontalAlignment:  "center",
    size: "small",
    type: "Image",
    url: "https://adaptivecards.io/content/airplane.png",
    selectAction: {
        data: { rate: 1 },
        type: "Action.Submit",
        title: "1"
      }
    }]
}]

and sotre the value in the state and on submit send the data to the bot
    */

      const SubmitHandler = async (event:any) => {
        event.preventDefault();
        setLoading(true);

        // check if the socket connection is closed then open it
        if(socketRef.current?.readyState === WebSocket.CLOSED){
          console.log('socket connection closed now opening');
          const rss = await botServiceRef.current?.getStreamUrl();

          openWebSocket(rss.streamUrl);
        }
        
        if (botServiceRef?.current === null || botServiceRef?.current === undefined) return
        
        // await botServiceRef.current?.sendMessage('Submitted');
        
          console.log('sending new message');
          const sentValue = await botServiceRef.current?.sendFormData({
            ...inputs,
            actionSubmitId: 'Submit'
          });
      
        //after sending the form data clear the inputs
        setInputs({});
        // setRate(0);

      setLoading(false);

        console.log('sent form data',sentValue);
      }
        
      const handleChange = (event:any) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
      }



return(
  <div>
{/* horizontal line */}
<div className='line'></div>
  <form 
  onSubmit={SubmitHandler}
  className="hero-card"
   key={index}>
  {content.body.map((item:any, idx:number) => {
    if(item.type === 'Input.Text'){
      return (
        // 
        <div className='formitem' key={idx}>
          <label 
          className='formlabel'
          >{item.label}</label>
          <input 
          className='forminput'
          required={item.isRequired}
          id={item.id}
          name={item.id}
          value={inputs ? inputs[item.id] : ''}
          onChange={handleChange}
          // type={item.type}
           placeholder={item.placeholder} >
          </input>
        </div>
      )
    }else if(item.type === 'ActionSet'){
      return (
        <div
        key={idx}>
          <button className='formsubmit' type='submit'>{item.actions[0].title}</button>
        </div>
      )
    }else if(item.type=== 'TextBlock'){
      return (
        <div key={idx}>
          <p className='TextBlock'>{item.text}</p>
        </div>
      )
    }else if(item.type === 'Input.Date'){
      return (
        <div className='formitem' key={idx}>
          <label
          className='formlabelwithbottomborder'
          >{item.label}</label>
          <input 
          className='forminputwithbottomborder'
          name={item.id}
          value={inputs ? inputs[item.id] : ''}
          onChange={handleChange}
          required={item.isRequired}
          id={item.id}
          type="date" ></input>
        </div>
      )
    }else if(item.type === 'ColumnSet'){
      return (
        <div>

        <div className='formrating' key={idx}>
          <p>{item.text}</p>
          {item.columns.map((column:any, index:number) => {
            return (

              <div 
              onClick={() =>{

                setRate(column.items[0].selectAction.data.rate)
                setInputs(values => ({...values, rate: column.items[0].selectAction.data.rate}))
              }}
              className= { 
             rate == index+1 ?
             index==0? 'formStarfirstfilled' : index == item.columns.length-1 ? 'formStarlastfilled' : 'formStarfilled'

             :
              
               index==0? 'formStarfirst' : index == item.columns.length-1 ? 'formStarlast' : 'formStar'
               
               }
                 key={index}>
                {column.items.map((item:any, idx:number) => {
                  return (
                    <div  key={idx}>
                      {/* <img src={item.url} alt={item.altText} /> */}
                      {/* just the number */}
                      <div className='ratenumber'>
                        {item.selectAction.data.rate}
                      </div>
                    </div>
                  )
                })}
              </div>

            )
          })}

        </div>

<div onClick={(e)=>{
  SubmitHandler(e);
 botServiceRef?.current?.startEndConversationWithType('endConversation');
}} className='endChatButton'>
<p>End chat</p>
</div>
</div>
        
      )
    }

  })}
</form>
  </div>
)




  }

  return (
    <div
    onClick={(e)=>{
      onFooterClick();
      e.stopPropagation();
    }}
    className="chat-container">
      <div 
      onClick={(e)=>e.stopPropagation()}
      className="sub-container">
        <div className="header">
          <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', paddingLeft: '1rem', alignContent: 'center', height: '3.75rem' }}>
            <img
            style={{ width: '2rem', height: '2rem', borderRadius: '50%' }}
            src={savvyicon} alt="Bot" />
            <p className="title">{'Savvy'}</p>
          </div>
        </div>
        <div ref={chat} className="messages">
          {messages.map(renderMessage)}
          {loading && <div className="loading">{"Loading..."}</div>}
        </div>
        {_button.length > 0 && (
          <div className="hero-card-buttons leftAlign">
            {_button.map((button:any, idx:number) => {
              if (button.type === 'options') {
                return (
                  <button
                    key={idx}
                    className={selectedOption === button.title ? 'hero-card-button selected' : 'hero-card-button'}
                    onClick={() => handleOptionClick(button)}
                  >
                    {button.title}
                  </button>
                );
              }else if (button.type === 'openUrl') {
                return (
                  <button
                  key={idx}
                   className="hero-card-button" onClick={() => handleOptionClick(button)}>
                    {button.title}
                  </button>
                );
              } else {
                return null;
              }

            })
            }
          </div>
        )}
      
        <div className="input-container">
          {
            // if the message is not a form like object has attachments or any options to select from then only show the input field
          (
            // messages.length > 0 && (messages[messages.length - 1].attachments === undefined  || messages[messages.length - 1]?.attachments?.length == 0) && (messages[messages.length - 1].suggestedActions === undefined || messages[messages.length - 1]?.suggestedActions?.actions?.length == 0)
          showInput
          ) &&
<>
<input
            className="input"
            type="text"
            value={inputValue}
            placeholder=''
            onChange={e => setInputValue(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && handleSendMessage()}
          />
          
          <button onClick={handleSendMessage} className="send-button">
          <img className='image' src={sendImg} /> 
          </button>
</>
          }
         
        </div>
        <div className="footer _downArrow">
          <button onClick={onFooterClick} className="footer-button">
          <img src={downArrow} alt="Bot" />
          </button>
          </div>
      </div>
    </div>
  );
};

export default CustomChatBot;
