//make a simple react component
import { on } from 'events';
import React from 'react';

interface SelectorProps {
  options: string[];
  onChange: (selected: string) => void;
}

const Selector: React.FC<SelectorProps> = ({ options, onChange }) => {

// const rate = options.indexOf(selected) + 1;

console.log(onChange);

    const [rate, setRate] = React.useState('');
   
    
    return (
        <div>

        <div 
        className={ options.length > 3 ? "hero-card-buttons" : "buttonsmorethan2len leftAlign"}
        >

        {options.map((button:any, index:number) => {
            return (
              <div
              className= { 
                (options.length > 3) ? 

                (
                rate == button.title ?
                index==0? 'formStarfirstfilledHorizontal' : index == (options.length ?? 0) - 1 ? 'formStarlastfilledHorizontal' : 'formStarfilledHorizontal'
                :
                  index==0? 'formStarfirstHorizontal' : index == (options.length ?? 0) - 1 ? 'formStarlastHorizontal' : 'formStarHorizontal'
                )
                  :
                  (
                rate == button.title ?
                index==0 ? 
                    

                'formStarfirstfilled' : index == (options.length ?? 0) - 1 ? 'formStarlastfilled' : 'formStarfilled'
                :
                  index==0? 'formStarfirst' : index == (options.length ?? 0) - 1 ? 'formStarlast' : 'formStar'

                  )
                }
              key={index}>
              <div

              //  className="hero-card-button" 
              className='ratenumber'
               onClick={() =>{
                
                if(rate == ''){

                    setRate(button.title);
                    onChange(button)
                }
                
               }}>
                {button.title}
              </div>
              </div>
            );
          

        })
        }
      </div>
        </div>
    );
}

export default Selector;

